@tailwind base;
@tailwind components;
@tailwind utilities;

/* 
   Variables
*/

:root {
  --button-text: 15px;

  --green: #40af61;
  --yellow: #ffc001;
  --purple: #683dae;
  --red: #e74f2f;
  --orange: #f47d36;
  --highlight-color: #fff4b8;

  --panelista-color: #1f9da3;
  --panelista-light-color: #e9f7f8;
  --panelista-dark-color: #0e494d;
  --like-color: #e91060;
  --error-color: #e00026;
  --notification-color: rgb(255, 69, 80);
  --success-bg: #e6f7ee;
  --success-color: #31b356;

  --black-text: #1d1f20;
  --dark-text: #3f4144;
  --secondary-button-bg-color: rgba(16, 49, 80, 0.03);
  --button-pressed-color: rgba(16, 49, 80, 0.05);
  --border-color: rgba(0, 8, 16, 0.07);
  --border-color-hover: #e3e6e9;
  --gray-text: #676d74;
  --light-gray-text: #999c9f;

  --light-background: #fafbfc;
  --lighter-background: #f7f8f9;

  --landing-header-height: 108px;
  --side-padding: 40px;
  --brand-radius: 6px;
  --manage-page-width: 1400px;

  --button-shadow: rgb(0 0 10 / 2%) 0px 4px 10px, rgb(0 0 10 / 2%) 0px 1px 2px;
  --card-shadow: rgb(0 0 0 / 2%) 0px 4px 10px, rgb(0 0 0 / 2%) 0px 2px 0px;
  --tall-shadow: 0px 8px 30px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.02);
  --tall-shadow-hover: 0px 8px 36px rgba(0, 0, 0, 0.08), 0px 1px 6px rgba(0, 0, 0, 0.04);

  --light-border: 1px solid var(--border-color);
  --light-border-hover: 1px solid #e3e6e9;

  --focus-color: rgba(0, 0, 5, 0.3);
}

@media (max-width: 1000px) {
  :root {
    --side-padding: 20px;
  }
}

@media (max-width: 800px) {
  :root {
    --landing-header-height: 80px;
  }
}

@media (max-width: 400px) {
  :root {
    --side-padding: 16px;
  }
}

@media (max-height: 800px) {
  :root {
    --landing-header-height: 80px;
  }
}

@layer components {
  .card {
    @apply bg-white rounded-lg shadow-card border border-[--border-color] border-solid;
  }

  .big-card {
    @apply bg-white pt-5 pb-2 px-6 rounded-xl shadow-tall max-[500px]:px-4 border border-[--border-color] border-solid;
  }

  .landing-section-header {
    @apply font-heading font-extrabold text-5xl/tight tracking-[-0.02em] my-7 max-[900px]:text-4xl max-[900px]:my-5 max-[600px]:text-3xl;
  }

  .info-label {
    @apply text-tiny text-[--gray-text] whitespace-pre-line;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@font-face {
  font-family: "Abstract Handwriting";
  src: url("/fonts/abstracthandwriting2-webfont.woff2") format("woff2"), url("/fonts/abstracthandwriting2-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.rich-text ul {
  list-style-type: disc;
  padding-left: 30px;
}

.rich-text ol {
  list-style-type: decimal;
  padding-left: 30px;
}

/* 
   Global styles
*/

html,
body,
button,
input,
textarea {
  font-family: var(--font-inter);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.02em;
}

html,
body,
div#root,
div#__next {
  height: 100%;
  min-height: 100%;
}

p,
li {
  line-height: 1.5;
  color: #1d1f20;
}

button {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

h3 {
  font-size: 17px;
  line-height: 1.3;
  font-weight: 600;
}

h2 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.3;
}

h1 {
  line-height: 1.2;
  font-weight: 500;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/

html {
  box-sizing: border-box;

  /* Disable double-tap to zoom on iOS */
  touch-action: manipulation;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
strong,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
canvas {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  -webkit-appearance: none;
}

input[type="text"],
input[type="email"] {
  -webkit-appearance: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */

html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */

a:focus {
  outline: 0;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */

a:active,
a:hover {
  outline: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */

form {
  margin: 0;
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */

button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */

button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"] /* 1 */,
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: none; /* 2 */
  cursor: pointer; /* 3 */
  overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */

textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #151818;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
input:-webkit-autofill::first-line {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: inherit !important;
}
